/*
Theme Name: Applin
Theme URI: https://cutter.com/applin/
Author: cutter team
Author URI: https://cutter.com/
Description: applin theme is a free apps theme.
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
Text Domain: 
Tags: apps, hosting,business etc.
*/

/*
===========================
TABLE INDEX
1. Bg Color
2. Bg Image
3. Form & Button
4. Section & Inner Title
5. Shape  Animation
6. Header Area
7. Service Area
8. Feature Area
9. Watch Video Area
10. Apps Screenshot Area
11. Apps Store Area
12. Testimonial Area
13. Price Plan  Area
14. Blog Area
15. Sidebar Area
16. community Area
17. Footer Area
18. Text Generic
===========================
*/

// All 	SCSS 
@import 'base/variables';
@import 'base/reset';
@import 'base/functions';
@import 'base/typography';
 

/* ==============================================

1. Bg Color

============================================== */
.bg-color1{
   background-color: $bg-color1; 
}
.bg-color2{
   background-color: $bg-color2; 
}
.bg-color3{
   background-color: $bg-color3; 
}
.bg-color4{
   background-color: $bg-color4; 
}
.bg-overlay:before{
	content:'';
    position:absolute;
	left:0;
	right:0;
	top:0;
	bottom:0;
	background-color:$bg-overlay;
	opacity:0.95;
	z-index:1;
}
.social-color1{
    color:$social-color1;
}
.social-color2{
    color:$social-color2;
}
.social-color3{
    color:$social-color3;
}
.social-color4{
    color:$social-color4;
}
div[class*="-wrapper"]{
	position:relative;
	z-index:3;
}
/* ==============================================

2. Bg Image

============================================== */
.bg-image1{
   background: $bg-color1 url("../images/cutter_head_light_theme.png") no-repeat fixed center/cover; 
}

.bg-image2{
   background: $bg-color1 url("../images/bg-image1.jpg") no-repeat fixed center/cover; 
}

.bg-image3{
   background: $bg-color1 url("../images/watch-video-bg.jpg") no-repeat fixed center/cover; 
}



/* ==============================================

3. Form & Button

============================================== */
form,
fieldset {
   padding:0;
   margin:0;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='search'], 
input[type='password'], 
textarea[class],select {
   transition:$transition-base;
   @include form-size(
      inherit,($form-space-v + 5),($form-space-h - 10),$form-radius
  ) ;
  @include form-variant(
      inherit,transparent,$form-border, inherit,$form-change-bg,$form-change-border2
  );
}


button,
input[type='button'],
input[type='reset'],
input[type='file'],
input[type='submit'] {
  transition:$transition-base;
  @include form-size(
      $form-size,$form-space-v,$form-space-h,$form-radius
  ) ;
  @include form-variant(
      $form-color,$form-bg2,$form-border2, $form-change-color2,$form-change-bg3,$form-change-border2
  );
  
}


/* ==============================================

4. Section & Inner Title

============================================== */
.section-title{ 
    position: relative;
    bottom: 9px;
	h2{
		margin-bottom:$inner-30;
		font-weight:$font-weight-semibold;
		
		@include laptop{
		    margin-bottom:20px;
		}
		@include tablet{
		    margin-bottom:20px;
		}
	}
	
	@include desktop{
	  bottom:5px;
	}@include laptop{
	  bottom:2px;
	}
	@include tablet{
	  bottom:0px;
	}
}
.inner-title{ 
    margin-bottom:$inner-30 - 10px;
	h3,h4,h5,h6{
	   margin:0;
	}
}


/* ==============================================

5. Shape  Animation

============================================== */
.cutter-ani{
	.shape-one{
		position: absolute;
    left: 9%;
    top: 28%;
    animation: animationFramesOne 25s alternate infinite linear;
	}
	.shape-two{
		    position: absolute;
    left: 38%;
    bottom: 25%;
    animation: animationFramesTwo 35s alternate infinite linear;
	}
	.shape-tree{
		    position: absolute;
    bottom: 60%;
    right: 10%;
    animation: animationFramesFour  20s infinite linear;
	}	
	.shape-four{
	 position: absolute;
        bottom: 10%;
    right: 40%;
    animation: rotated  20s infinite linear;
	}
	.shape-five{
	 position: absolute;
    bottom: 20%;
    left: 10%;
    animation: animationFramesFive  20s infinite linear;
	}
	.shape-six{
	 position: absolute;
    animation: rotated  50s infinite linear;
	}
	@include tablet{
	   display:none;
	}
}
/* ==============================================

6. Header Area

============================================== */
.main-area{
   //overflow:hidden;
   overflow-x: hidden;
}
.header-area{

    margin-bottom: 455px;
	&:before{
    content: "";
    height: 700px;
    border-bottom-left-radius: 220px;
    border-bottom-right-radius: 270px;
    background: $bg-color2;
    display: block;
    position: absolute;
    transform: skewY(6deg);
	left: -50px;
    right: -70px;
    bottom: -250px;
	z-index:1;
	@include desktop{
	    bottom: -190px;
		border-bottom-left-radius: 200px;
        border-bottom-right-radius: 200px;
	}
	@include laptop{
	   display:none;
	}
	@include tablet{
	  display:none;
	}
  }
  .header-banner{
     padding:30px 50px 0px 0px;
	 z-index: 3;
     position: relative;
	 @include desktop{
	    padding:30px 0px 0px 0px;
	 } 
	 @include laptop{
	    padding:20px 0px 100px 0px;
	 }
	 @include tablet{
	    padding:10px 0px 80px 0px;
	 }
  }
  @include desktop{
      margin-bottom:228px;
  }
   @include laptop{
       margin:0;
   }
   @include tablet{
       margin:0;
   }
}
.page-area{

   margin-bottom:205px;
@include desktop{
        margin-bottom: 213px;
   }
   @include laptop{
      margin:0;
   } 
   @include tablet{
      margin:0;
   }
   
&:before{
    bottom: -130px;
    border-bottom-left-radius: 170px;
    border-bottom-right-radius: 150px;
	@include desktop{
	    bottom: -170px;
	}
	@include laptop{
	    bottom: -170px;
	}
	@include tablet{
	    bottom: -170px;
	}
  }
   
   .header-page-title{
	   padding-top:30px;
	   padding-bottom:20px;
       @include laptop{
	      padding-top: 20px;
	   }
       @include tablet{
	      padding-top: 10px;
	   }
   }
   
   
   .header-shape {
      .left {
		left: -190px;
		top: -770px;
		
		@include desktop{
    left: -100px;
    top: -305px;
    width: 470px;
		}
		@include laptop{
    left: -120px;
    top: -300px;
    width: 470px;
		}
		@include tablet{
		   left: -140px;
			top: -220px;
			width: 470px;
		}
		
	}
	.right {
    right: -670px;
    top: -610px;
	  @include desktop{
			right: -250px;
			top: -90px;
			width: 480px;
		}
		@include laptop{
    right: -250px;
    top: -180px;
    width: 480px;
		}
		@include tablet{
			right: -250px;
			top: -90px;
			width: 480px;
		}
	}
   }
}
.header-shape{
	position:absolute;
	left:0;
	right:0;
	top:0;
	z-index:2;
	.left{
	position:absolute;
    left: -190px;
    top: -690px;
	
	@include desktop{
    left: -140px;
    top: -220px;
    width: 470px;

	}
	@include laptop{
	        left: -80px;
    top: -220px;
    width: 400px;
	}
	}
	.right{
	position:absolute;
    right: -640px;
    top: -610px;
	@include desktop{
    right: -295px;
    top: -90px;
    width: 600px;
	}
	@include laptop{
    right: -250px;
    top: -90px;
    width: 480px;
	}
	}
	
	img{
	    max-width:100%;
		
		@include laptop{
			max-width:80%;
		}
		@include tablet{
			max-width:80%;
		}
	}
	
	@include tablet{
	    display:none;
	}
}
.header-nav{
   padding-top:$inner-70;
   
   @include laptop{
      padding-top:$inner-30;
   }
   @include tablet{
      padding-top:$inner-30;
   }
   display:table;
   width:100%;
   position:relative;
   z-index:10;
    .cutter-navbar{
	   padding:0;
	}

	.header-logo{
	   padding:15px 0;
	   float:left;
	}
	
   .cutter-navbar{
      float:right;
	  @include laptop{
	      float:none;
	  } 
	  @include tablet{
	      float:none;
	  }
   }	
  
.main-menu {
   @include laptop{
   position:absolute;
   left:0;
   right:0;
   background-color:$bg-color1;
   box-shadow:$box-shadow;
   border-radius:$border-radius;
   top: 0;
   }  
   @include tablet{
   position:absolute;
   left:0;
   right:0;
   background-color:$bg-color1;
   box-shadow:$box-shadow;
   border-radius:$border-radius;
   top: 0;
   }
   li{
   
   border: 0 !important;
  background-color: transparent;
  a {
    padding: 15px 15px 15px 15px;
    color: $font-color4;
	
	@include laptop{
	   color:$font-color-base;
	}	
	@include tablet{
	   color:$font-color-base;
	}
  }
  &.has-submenu a {
    padding: 15px 30px 15px 15px;
  }
  &.has-submenu {
    ul li {
      a {
        color: $font-color-base;
        padding: 15px 15px;
		
		transition:$transition-base;
        &:hover {
          text-indent: 2px;
          color: $font-color3;
        }
      }
      background-color: $bg-color1;
    }
    > ul:before {
      content: "";
      background-color: $bg-color1;
      width: 15px;
      height: 15px;
      display: block;
      position: relative;
      left: 10px;
      top: 10px;
      transform: rotate(45deg);@include laptop{
	   display:none;
	}@include tablet{
	   display:none;
	} 
    }
  }
  .sub-toggle {
    background: transparent;
    width:20px;
    i:before {
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      content: "\f107";
      position: absolute;
      color: $font-color4;
      font-style: normal;
	  @include tablet{
	      color: $font-color2;
	  }
	  @include laptop{
	      color: $font-color2;
	  }
    }
	@include laptop{
	    width: 45px;
        height: 100% !important;
	}
	@include tablet{
	    width: 45px;
        height: 100% !important;
	}
  }
  a {
    &:hover, &:focus {
      background-color: transparent;
      color: $font-color4;
	  
	  @include laptop{
	     color: $link-color;
	  } 
	  @include tablet{
	     color: $link-color;
	  } 
    }
  }
  .sub-toggle i {
    color: transparent;
    position: relative;
  }
  &.has-submenu ul {
    position: absolute;
    min-width: 180px;
    li {
      float: none;
      display: block;
    }
  }  
   }
  
}
.menu-collapser{
   .collapse-button{
         top: -38px;
		 .icon-bar{
		    background-color:$bg-color1;
		 }
   }
}
.col-lg-10{
   @include laptop{
      position:static;
   }
   @include tablet{
      position:static;
   }
}
}
@-webkit-keyframes banner-mockup {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes banner-mockup {
  0% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
  50% {
    -webkit-transform: translateY(4px);
    transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@-webkit-keyframes banner-mockup-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
@keyframes banner-mockup-sink {
  100% {
    -webkit-transform: translateY(8px);
    transform: translateY(8px);
  }
}
.header-banner{
	.banner-content{
	   padding-right:$inner-30;
	   position:relative;
	   z-index:2;
	 	   
	   @include laptop{
	      padding-right:0;
	   }  
	   @include tablet{
	      padding-right:0;
	   }
	}
	
	.banner-mockup{
	  z-index:2;
	   position:absolute;
		animation-name: banner-mockup-sink, banner-mockup;
		animation-duration: .3s, 1.5s;
		animation-delay: 0s, .3s;
		animation-timing-function: ease-out, ease-in-out;
		animation-iteration-count: 1, infinite;
		animation-fill-mode: forwards;
		animation-direction: normal, alternate;
		
		@include tablet{
	  display:none;
	  
	}
	@include desktop{
	   margin:0;
	   img{
	       max-width:100%;
	   }
	}@include laptop{
	   margin:0;
	   img{
	       max-width:100%;
	   }
	}
	}
	.banner-btn{
	   li{
	      margin-right:20px;
		  margin-bottom:10px;
		  &:last-child{
		     margin:0;
		  }
		  
		  @include mobile{
			  display:block;
			  margin-right:0;
			  a{
				  display:block;
			  }
		  }
	   }
	}
}
.header-page-title{
	position:relative;
	z-index:3;
	padding-top:30px;
	padding-bottom:20px;
	.page-post-date{
	   margin:0;
	   li{
		   margin-right:20px;
		   img{
			   	margin-right: 10px;
				width: 15px;
				height: 15px;
				object-fit: contain;

		   }
	   }
	}
	.author-name{
	    img{
		    width:65px;
			height:65px;
			border-radius:$round-radius;
			margin-bottom:20px;
			object-fit:cover;
		}
		.social-link{
		   margin:0;
		   margin-top:10px;
		}
	}
	@include tablet{
	    padding-top:60px;
	    padding-bottom:90px;
	}
	@include laptop{
	    padding-top:80px;
	    padding-bottom:100px;
	}
}

.dwn-btn1{
  @include form-size(
      $form-size2,$form-space-v + 6, $form-space-v + 10,$form-radius
  ) ;
  @include form-variant(
      $form-color2,$form-bg,$form-border3, $form-change-color2,$form-bg3,$form-change-border3
  );
  span{
     margin-left:10px;
  }
  img{
	  height:20px;
	  width:20px;
	  object-fit:contain;
  }
}
.dwn-btn2{
  @include form-size(
      $form-size2,$form-space-v + 5, $form-space-v + 10,$form-radius
  ) ;
  @include form-variant(
      $form-color,transparent,$form-border3, $form-change-color,$form-change-bg4,$form-change-border4
  );
  span{
     margin-left:10px;
	 vertical-align: middle;
  }
  img{
	  height:20px;
	  width:20px;
	  object-fit:contain;
  }
}

.dwn-btn3 {
	@include form-size(
		$form-size2,$form-space-v + 6, $form-space-v + 10,$form-radius
	) ;
}
/* ==============================================

7. Service Area

============================================== */
.line-color1{
	background-color:#fec2c2;;
}
.line-color2{
	background-color:#b0ffca;
}.line-color3{
	background-color:#d8c8fc;
}
.service-box{
	text-align:center;
	margin-bottom:$inner-30;
	.service-logo{
		padding:21px 5px;
		width:85px;
		height:85px;
		border-radius:$round-radius;
		box-shadow:$box-shadow-deep;
		display:block;
        text-align:center;
        vertical-align:middle;
        margin:auto auto $inner-30;	
		transition:$transition-base;
	}
	.service-content{
		
		h5,h4,h3{margin-bottom:20px;}
		p{ margin-bottom:$inner-30; }
	}
	.service-line{
		position: relative;
		width:2px;
		height:70px;
		display:block;
		top:0;
		margin:auto auto 0;	
		transition:$transition-base;
	}
	&:hover{
		.service-logo{
			box-shadow:$box-shadow;
			
		}
		.service-line{
			top: -15px;
		}
	}
}


.icon-shadow{
	text-shadow: -7px 8px 2px  $font-color8, -3px 5px  $font-color7;
  }

.icon-glow {
	text-shadow: -3px -8px 9px $font-color6;

}
/* ==============================================

8. Feature Area

============================================== */



.feature-area {

	@include mobile{
		padding-top: 50px;
	  }
}


.feature-box{
	overflow:hidden;
	position:relative;
	box-shadow:$box-shadow;
	padding:$inner-30 $inner-30  $inner-50;
	margin-bottom:$inner-30;
	text-align:center;
	border-radius:$border-radius;
	transition:$transition-base;
	.feature-logo{
		/*width:85px;*/
		/*height:85px;*/
		box-shadow:$box-shadow-deep;
		border-radius:$round-radius;
		margin:auto auto $inner-30;
		padding:21px 5px;
		transition:$transition-base;
		height: 250px;
		img {
			width: 90%;
			max-height: 100%;
		}
	}
	&:after{
		content:"";
		width:170px;
		height:1px;
		display:block;
		background:#6d49bf;
		opacity:0.14;
		position:absolute;
		top:50px;
		left:30px;
		
		@include laptop{
		    display:none;
		}
	}
	&:before{
		content:"";
		width:1px;
		height:170px;
		display:block;
		background:#6d49bf;
		opacity:0.14;
		position:absolute;
		top:30px;
		left:50px;
		@include laptop{
		    display:none;
		}
	}
	.feature-content{
		h3,h4,h5{
			
			margin-bottom:$inner-30 - 10;
		}
		p{
			margin:0;
		}
	}
	.feature-shape{
		width: 100%;
		height: 110px;
		background: $bg-color1;
		opacity:0.03;
		display: block;
		border-radius: $round-radius;
		position: absolute;
        top: -8px;
        right: -140px;
		transform: rotate(-55deg);
		}
		&:hover{
			background-color:$bg-color2;
			*{
				color:$font-color4;
			}
			.feature-logo{
				background-color:$bg-color1;
			}
			&:before{
				background-color:$bg-color1;
				opacity:0.15;
			}
			&:after{
				background-color:$bg-color1;
				opacity:0.15;
			}
		}
		@include laptop{
		    padding: $inner-40 15px;
		}
}
.feature-img{
    margin-bottom:30px;
	
	.image-mockup{
		
		@include laptop{
		  max-width:500px;
		}
		@include tablet{
		  max-width:380px;
		}
	}
	
}


/* ==============================================

9. Watch Video Area

============================================== */

.watch-video-play{
	position:relative;
		margin-top:240px;
	img{
		max-width:100%;
	border-radius:$border-radius;
	}
	@include laptop {
	   margin-top:0;
	   margin-bottom:30px;
	   padding-right:15px;
	}	
	@include tablet {
	   margin-top:0;
	   margin-bottom:30px;
	   padding-right:15px;
	}
.play-btn {
  padding:29px 10px;
  display: block;
  text-align:center;
  width: 90px;
  height: 90px;
  border-radius: $round-radius;
  background: $bg-color1;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(255, 255, 255, 0.8);
  animation: play-btn 1.5s infinite;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
	margin: auto;
  i{
	  font-size:32px;
  }
  &:hover {
    -webkit-animation: none;
    animation: none;
  }
}

.play-btn {
	&.disabled {
		pointer-events: none;
		cursor: default;
	}
}


@keyframes play-btn {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
  }

  70% {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}
}
.watch-video-content{
	
	.watch-video-top{
		background-color:$bg-color3;
		border-top-left-radius:$border-radius;
		border-top-right-radius:$border-radius;
		padding:$inner-30;
		h2{
			margin:0;
		}
		@include laptop{
		   padding:$inner-30 - 10;
		}
		@include tablet{
		   padding:$inner-30 - 10;
		}
	}
	.watch-video-bottom{
		border-bottom-left-radius:$border-radius;
		border-bottom-right-radius:$border-radius;
	    background-color:$bg-color1;
		padding:$inner-30;
		p{ margin:0; }
		
		@include laptop{
		   padding:30px;
		}@include tablet{
		   padding:30px;
		}
		
	}
	@include laptop{
	   padding-left:15px;
	}
	@include tablet{
	   padding-left:15px;
	}
	
}
.watch-arrow{
	margin:20px;
	
	@include laptop{
	   display:none;
	}
	@include tablet{
	   display:none;
	}
}



/* ==============================================

10. Apps Screenshot Area

============================================== */
.apps-screenshot-img{
	img{
		    position: relative;
			max-width: 100%;
		
		@include desktop{
			margin-left:0;
			max-width:100%;
		}@include laptop{
			margin-left:0;
			max-width:100%;
			margin-bottom:$inner-30;
		}
		@include tablet{
			margin-left:0;
			max-width:100%;
			margin-bottom:$inner-30;
		}
	}
}
.screenshot-list{
	 position:relative;
	li{
		padding-left:25px;
		margin-bottom:5px;
		&:before{
			  font-family: "Font Awesome 5 Free";
			  font-weight: 900;
			  content: "\f068";
			  position:absolute;
			  left:0;
			  color:$font-color3;
		}
	}
}
.screenshot-content{
    margin-bottom:$inner-30;
}
.swiper-container{
    margin-bottom:$inner-30;
}
.apps-download-link{
	p{
		color:$font-color2;
 
	}
}

/* ==============================================

11. Apps Store Area

============================================== */
.download-list{
	 position:relative;
	li{
		padding-left:20px;
		margin-bottom:5px;
		&:before{
				font-family: "Font Awesome 5 Free";
				font-weight: 900;
				content: "\f111";
				font-size: 8px;
				position: absolute;
				line-height: 30px;
				left: 0;
			  color:$font-color3;
		}
	}
}


.download-content{
	.banner-btn{
	   li{
	      margin-right:20px;
		  margin-bottom:10px;
		  &:last-child{
		     margin:0;
		  }
		  
		  @include mobile{
			  display:block;
			  margin-right:0;
			  a{
				  display:block;
			  }
		  }
	   }
	}
}


	
.cutter-graph-img{
	img{
		z-index:2;
		position:relative;
		
		@include desktop{
		
		   max-width: 380px;
		}@include laptop{
		
		   max-width: 380px;
		}
		@include tablet{
		
		  display: none;
		}
	}
	@include tablet{
	    margin-bottom:$inner-30;
	}
}

.download-btn{
   li{
      margin-top:10px;
	  @include mobile{
	     display:block;
		 float:none;
		 margin-right:0 !important;
		 
		 a{
		    width:100%;
		 }
	  }
   }
}
.shape-six{
	
    bottom: 0;
    right: -650px;
	
	@include desktop{
		right: -350px;
		max-width: 480px;
	}	
	@include laptop{
		right: -407px;
		max-width: 480px;
	}
}

/* ==============================================

12. Testimonial Area

============================================== */
.testimonial-thumbs{
	width: 100%;
    height: 100%;
	position:relative;
	margin:0;
	min-height: 350px;
	li{
			width:100%;
			object-fit:cover;
			width: 80px;
         height: 80px;
		border-radius:$round-radius;
		overflow:hidden;
		position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		margin:auto;
		cursor:pointer;
		box-shadow:$box-shadow-deep;
		img{
			object-fit:cover;
			width:80px;
			height:80px;
		}
		&:before{
			content:"";
			position:absolute;
		top:0;
		left:0;
		bottom:0;
		right:0;
		z-index:2;
		background:$bg-overlay;
		opacity:0;
		transition:$transition-base;
		}
		
	}
	.active{
		&:before{
			opacity:0.5;
		}
	}
	li:nth-child(1){
      
    left: -270px;
    top: -267px;
	 
	 @include laptop{
	     left: -150px;
	 }

	}
	li:nth-child(2){
      
    left: 270px;
    top: -180px;
	@include laptop{
	    left: 180px;
	}

	}
	li:nth-child(3){
     
    left: -270px;
    top: 120px;
	
	@include laptop{
	       left: -145px;
           top: 220px;
	}

	}
	li:nth-child(4){
       
    right: -210px;
    top: 270px;

		

	}
	li:nth-child(5){
     
		  width:60px;
		height:60px;
		
		img{
			width:60px;
			height:60px;
		}


	}
	@include tablet{
	    
	}
}
.testimonial-slider{

	.author-content{
		position:relative;
		box-shadow:$box-shadow-spead;
		padding:$inner-60 $inner-70 $inner-70 $inner-70;
		margin:30px 0;
		&:before{
			content: "";
			background-image: url(../images/quote-top.png);
			width: 90px;
			height: 76px;
			background-size: cover;
			display: block;
			position: absolute;
			top: -30px;
			left: 0;
			opacity:0.3;
			z-index:2;
		}
		&:after{
			content: "";
			background-image: url(../images/quote-bottom.png);
			width: 90px;
			height: 75px;
			background-size: cover;
			display: block;
			position: absolute;
			bottom: -30px;
			right: 0;
			opacity:0.3;
			z-index:2;
		}
		@include laptop{
		    padding:$inner-30 $inner-30;
			box-shadow:$box-shadow;
		}
		@include tablet{
		    padding:$inner-30 $inner-30;
			box-shadow:$box-shadow;
		}
	}
	.author-desc{
		h2{
			margin-bottom:$inner-40;
			font-weight:$font-weight-semibold;
			
			@include laptop{
				margin-bottom:$inner-30;
			}
			@include tablet{
				margin-bottom:$inner-30;
			}
		}
		p{
			margin-bottom:$inner-40;
		}
	}
	.author-name{
		h4,h5{
			margin:0;
		}
		a{
			color:$font-color2;
			font-weight:$font-weight-semibold;
			display:block;
			margin-bottom:5px;
			&:hover{
			   color:$font-color3;
			}
		}
		span{
			display:block;
		    @include font-size($small-font-size);
			font-weight:$font-weight-normal;
			color:$font-color-base;
		}
	}
	.owl-dots{
	    text-align: left;
        margin-top: $inner-30;
        margin-bottom: $inner-30;
		padding-left:15px;
	    .owl-dot{
		   width:15px;
		   height:15px;
		   margin-right:10px;
		   border-radius:$round-radius;
		   background-color:$bg-color4;
		}	    
		.owl-dot.active{
		   background-color:$bg-color2;
		}
		
		@include desktop{
			display:none;
		}
		@include laptop{
			display:none;
		}
	}
	
	@include laptop{
		margin-bottom:$inner-30;
	}
}




/* ==============================================

13. Price Plan  Area

============================================== */
.price-plan-title{
    position:relative;
	bottom:10px;
	h2{
		font-weight:$font-weight-semibold;
		margin-bottom:$inner-30;
	}
	p{
	    margin-bottom:$inner-50;
		@include laptop{
		  margin-bottom:$inner-30;
		}
		@include tablet{
		  margin-bottom:$inner-30;
		}
	}
	@include laptop{
	  margin-bottom:$inner-40;
	  bottom:0px;
	}
	@include tablet{
	  margin-bottom:$inner-40;
	  bottom:0px;
	}
}
.price-plan-box{
	   box-shadow:$box-shadow-deep;
		border-radius:$border-radius;
		overflow:hidden;
		margin-bottom:$inner-30;
	.price-title{
		display:table;
		width:100%;
		.price-curency{
			width:140px;
			height:120px;
			background-color:$bg-color2;
			float:left;
	     	p{
				@include font-size($big-size1); 
				font-weight:$font-weight-light;
				color:$font-color4;
				margin: 6px 0px;
				sup{
					font-size:30px;
					top:-25px;
					margin-right: 5px;
				}
				@include laptop{
				    font-size: 40px;
                    margin: 30px 0px;
				}
			}
			@include laptop{
			    width:100px;
			}
		}
		.price-package{
			width:calc(100% - 140px);
			height:120px;
			background-color:$bg-color4;
			float:left;
			h5{
				@include font-size($h5-font-size);
				color:$font-color2;
				margin: 0;
			}
			
			@include laptop{
			    width: calc(100% - 100px);
			}
		}
		
	}
	.price-content{
		
	     background-color:$bg-color1;
		 padding:$inner-50 $inner-30 $inner-60;
		.price-list{
			margin-bottom:$inner-50;
			li{
				margin-bottom:15px;
			}
		}
		.price-btn{
			  @include form-size(
				  $form-size,$form-space-v + 5,$form-space-h + 10,$form-radius
			  ) ;
			  @include form-variant(
 
				  $form-color,$form-bg2,$form-border2, $form-change-color2,$form-change-bg,$form-change-border2 
 
			  );
			  @include mobile{
			      width:100%;
			  };
		}
		
		
		@include laptop{
		    padding: 30px 15px;
		}
	}
	@include tablet{
		margin-top:0;
	}
}




/* ==============================================

14. Blog Area

============================================== */
.blog-area{
	
	.all-view-btn{
		
    position: absolute;
    right: 15px;
	
	@include tablet{
	  display:none;
  }
	}
}
.blog-media{
    img{
	   width:100%;
	}
}
.blog-post{
		 margin-bottom:$inner-30;
	.post-img{
		img{
			width:100%;
			 
		}
	}
	.post-content{
		 
		overflow:hidden;
		position:relative;
		padding:$inner-60 $inner-50;
		box-shadow:$box-shadow-deep;
		transition:$transition-base;
	 
		background-color:$bg-color1;
		width:calc(100% - 60px);
		top:0;
		right:-30px;
		margin-top:-150px;
		
		@include tablet{
		    margin: 0;
			position: relative;
			width: 100%;
			margin-top: 0;
			padding: 30px 15px;
			right:0;
		 
		}
		@include laptop{
		     margin: 0;
			position: relative;
			width: 100%;
			margin-top: 0;
			padding: 30px 15px;
			right:0;
		 
		}	
		@include desktop{
		 	 margin: 0;
			position: relative;
			width: 100%;
			margin-top: 0;
			padding: 30px 15px;
			right:0;
			 
		}
		&:hover{
		   box-shadow:$box-shadow;
		}
	}
	.post-date{
		width:76px;
		background-color:$bg-color2;
		display:block;
		position:absolute;
		top:0;
		font-weight:$font-weight-light;
		right:0;
		font-size: 40px;
		line-height: 30px;
		color:$font-color4;
		text-align: center;
		padding: 15px 10px 10px;
		span{
			display:block;
			font-size:16px;
		}
	}
	.meta-info{
		margin:0;
		margin-top:10px;
		li{
			font-weight:$font-weight-light;
			margin-right:20px;
			font-size:14px;
			a{
				font-size:14px;
				color:$font-color-base ;
			}
			i{
				margin-right:5px;
			}
		}
	}
	.post-author{
		 display:table;
		 width:100%;
		 margin-bottom:$inner-30;
		.author-img{
			width:40px;
			height:40px;
			border-radius:30px;
			overflow:hidden;
			float:left;
			img{
				width: 40px;
                height: 40px;
				object-fit:cover;
			}
		}
		.author-content{
			width:calc(100% - 40px);
			padding-left:10px;
			line-height:18px;
			font-weight:$font-weight-medium;
			float:left;
			> a{
				display:inline-block;
				color:$font-color2;
				font-weight:$font-weight-semibold;
				&:hover{
				   color:$font-color3;
				}
			}
			span{
				@include font-size($small-font-size);
				font-weight:$font-weight-normal;
			}
		}
	}
	
	.post-desc{
		h5{
			margin-bottom:$inner-30;
		}
		p{
			margin-bottom:$inner-30;
		}
	    .post-btn{
			 color:$font-color3;
			 padding:0;
			 font-weight:$font-weight-semibold;
			 &:hover{
			    color:$font-color2;
			 }
		}
		
	}
}
.reverse-loop>.row>.col-md-6:nth-child(4n + 2) .blog-post,
.reverse-loop>.row>.col-md-6:nth-child(4n + 3) .blog-post{
    width: 100%;
    height: calc(100% - 30px);
   .post-img{
        position:absolute;
		bottom: 0;
		
		@include desktop{
		    position:relative;
		}@include laptop{
		    position:relative;
		}
		@include tablet{
		    position:relative;
		}
   }
   .post-content{
      margin-top:0;
	  margin-bottom:150px;
	  @include desktop{
		margin-bottom:0;
	 
	} @include laptop{
		margin-bottom:0;
		 
	} @include tablet{
		margin-bottom:0;
		 
	}
   }
}
.all-view-btn{
	display:inline-block;
	@include form-size(
      inherit,($form-space-v + 5),($form-space-h + 10),$form-radius
  );
box-shadow:$box-shadow-deep;  
  @include form-variant(
      $form-color2,$form-bg,$form-border4, inherit,$form-change-bg,$form-change-border2
  );
  
}
.author-post{
    text-align:center;
	margin-bottom:$inner-60;
    .author-img{
	    width:100px;
		height:100px;
		border-radius:$round-radius;
		overflow:hidden;
		box-shadow:$box-shadow-deep;
		transition:$transition-base;
		margin:auto;
		margin-bottom:20px;
		img{
		width:100px;
		height:100px;
		    width:100%;
			object-fit:cover;
		}
	}
    .author-content{
	       h6{
		      margin:0;
		   }
	}
	&:hover{
	    .author-img{
		   box-shadow:$box-shadow;
		}
	}
	@include tablet{
	   margin-bottom:$inner-30;
	}
	@include laptop{
	   margin-bottom:$inner-30;
	}
}
.page-navigation{
	.pagination{
		margin:0;
	}
	li:first-child a ,
	li:last-child a {
		border-radius: 0 !important;
	}
	li{
		a{
			border:1px solid #f7f7f7;
			width:40px;
			height:40px;
			background-color:$bg-color4;
			margin-right: 20px;
			font-size: 20px;
			padding: 7px 4px;
			text-align: center;
			&:hover, &.active{
				background-color:$bg-color3;
				border-color:$bg-color3;
				color:$font-color4;
			}
		}
	}
}
.entry-content{ 
   .first-word{
	   font-size:70px;
	   color:#b3cefc;
	   line-height:28.8px;
   }
   img{
	   margin-bottom:$inner-30;
	   width:100%;
   }
   .list-dot{
	   li:before{
		  font-family: "Font Awesome 5 Free"; 
		  font-weight: 400; 
		  content: "\f111";	 
          margin-right:15px;
          font-size:12px;
          color:$font-color3;		  
	   }
   }
	blockquote{
		 @include font-size($h5-font-size);
		 font-style:italic;
		 margin:$inner-30 0;
		 
	}
}

.blog-share-tags{
  display:table;
  width:100%;
  box-shadow:$box-shadow;
  padding:10px 25px;
  border-radius:$border-radius;
  border:$form-border4;
  .blog-author-tags{
      float:left;
	  @include laptop{
		 float:none;
	 }@include tablet{
		 float:none;
	 }
	  ul{
	     margin:0;
		 li{
		    &:first-child{
			   color:$font-color2;
			   font-weight:$font-weight-semibold;
			}
			a{
			   color:$font-color-base;
			   &:hover{
			      color:$font-color3;
			   } 
			}
		 }
	  }
  }
  .blog-author-share{
     float:right;
	 
	 @include tablet{
		 float:none;
		 margin-top:10px;
	 }
	 @include laptop{
		 float:none;
		 margin-top:10px;
	 }
	 ul{
	    margin:0;
		li{
		   margin-right:15px;
		   &:last-child{
		      margin:0;
		   }
		   a{
		     color:$font-color-base;
			 @include font-size($small-font-size);
			 &:hover{
			    color:$font-color3;
			 }
		   }
		}
	 }
  }
}

.blog-author-admin{
    display:table;
	width:100%;
   .author-avatar{
       width:135px;
	   height:135px;
	   float:left;
   }
   .author-info{
        width:calc(100% - 135px);
		padding-left:25px;
		float:left;
		.social-link{
		    margin:0;
			
			li a{
			   color:$font-color-base;
			   @include font-size($small-font-size);
			   
			}
		 }
   }
}

.page-pagination{
   .pagination{
    margin:0;
	width:100%;
      .prev,.next{
	      border-radius:$border-radius;
		  padding:15px 30px;
		  box-shadow:$box-shadow;
		  border:$form-border4;
		  color:$font-color2;
	  }
   }
}

.latest-slider{
    position:relative;
   .blog-latest{
      .latest-img{
	      img{
		     width:100%;
		  }
	   }
	   .latest-content{
	      transition:$transition-base;
	       text-align:center;
	       padding:20px;
		   box-shadow:$box-shadow;
		   border-bottom-left-radius:$border-radius;
		   border-bottom-right-radius:$border-radius;
		   border:$form-border4;
		   
	   }
   }
   .blog-latest:hover{
       .read-more-btn{
	       color:$font-color2;
	   }
   }
   .owl-nav{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    height: 40px;
    margin: auto;
	
   }
   .owl-nav button.owl-prev span,
   .owl-nav button.owl-next span{
      font-size:30px;
	  line-height: 30px;
   }
   .owl-nav button.owl-prev{
      border:transparent;
	  background-color:$bg-color3;
	  color:$font-color4;
	  width:40px;
	  height:40px;
	  margin-left:15px;
	  float:left;
	  border-radius:$round-radius;
	  box-shadow:$box-shadow;
   }  
   .owl-nav button.owl-next{
      border:transparent;
	  background-color:$bg-color3;
	  color:$font-color4;
	  width:40px;
	  height:40px;
	  float:right;
	  margin-right:15px;
	  border-radius:$round-radius;
	  box-shadow:$box-shadow;
   }

}

.comment-wrapper{
   .comments-box{
     display:table;
	 width:100%;
	 
     .comment-avatar{
	    width:65px;
		height:65px;
		object-fit:cover;
		float:left;
		img{
		   border-radius:$round-radius;
		}
	 }
	 .comment-content{
	     width:calc(100% - 65px);
		 padding-left:15px;
		 float:left;
		 .comment-date{
		    @include font-size($small-font-size);
			font-weight:$font-weight-normal;
			color:$font-color-base;
		 }
	 }
   }
}

#watch-video{
	background-color:rgba(255,255, 255, 0.1);

	.modal-content{
		border:0;
		box-shadow:$box-shadow;
	}
   .modal-header{
	   border:0;
	   padding-bottom:0;
   }
   .modal-body{
	   
   }
   [type="button"]{
	   &:hover{
		   border:0;
	   }
   }
}
.blog-spacing{
    margin-bottom:$inner-60;
	
	@include laptop{
	    margin-bottom:$inner-30;
	}
	@include tablet{
	    margin-bottom:$inner-30;
	}
}
.modal-backdrop.show {
    opacity: 1;
    background: $bg-color1;
}


/* ==============================================

15. Sidebar Area

============================================== */
.sidebar-title{
    margin-bottom:$inner-30;
}

.sidebar-form{
    form{
	   border:$form-border4;
	   border-radius:$border-radius;
	}
   input{
	 @include form-size(
		  $form-size,$form-space-v + 5 ,$form-space-h,none
	  ) ;
	@include form-variant(
		  $form-color2,transparent,none, $form-color2,transparent,none
	  );
}
button{
	@include form-variant(
		  $form-color2,transparent,none, $form-color2,transparent,none
	  );
	   @include form-size(
		  $form-size,$form-space-v,$form-space-h,$form-radius
	  ) ;
}
}

.sidebar-list{
   ul{
      li{
	     padding-left:0;
	     border:0;
		 border-top:$form-border4;
		 border-bottom:$form-border4;
		 &:first-child{
		    border-top:0;
			padding-top:0;
		 } &:last-child{
		    border-bottom:0;
			padding-bottom:0;
		 }
		 a{
		    color:$font-color-base;
		 }
	  }
   }
}
.sidebar-blog{
    .sidebar-latest{
	    display:table;
		width:100%;
	   .latest-img{
	       width:65px;
		   height:65px;
		   float:left;
		   img{
		      object-fit:cover;
		   }
	   }
	   .latest-content{
	      width:calc(100% - 65px);
		  padding-left:15px;
		  float:left;
	   }
	}
}
.sidebar-social-link{
   ul{
      margin:0;
      li{ 
	     text-align:center;
		 border:$form-border4;
		 border-radius:$border-radius;
		 padding:15px 20px;
		 margin-bottom:10px;
	     a{
		   display:inline-block;
		 }
		 i{
		    font-size:25px;
		 }
		 p{
		    margin:0;
			font-size:18px;
			color:$font-color2;
			line-height: 24px;
			font-weight:$font-weight-semibold;
			span{
			   display:block;
			   color:$font-color-base;
			   font-weight:$font-weight-normal;
			   font-size:16px;
			}
		 }
		 
		 @include laptop{
		     padding: 5px 10px;
		 }
	  
	  }
   }
}
.sidebar-widget{
   margin-bottom:$inner-30;
   
   @include tablet{
       margin-bottom:$inner-30;
   }
   @include laptop{
       margin-bottom:$inner-30;
   }
   
}
.sidebar-right{
    @include tablet{
	   margin-top:$inner-60;
	}
}
.sidebar-left{
   @include tablet{
	   margin-bottom:$inner-60;
	}
}
/* ==============================================

16. community Area

============================================== */
.community-form{
	box-shadow:$box-shadow-deep;
	padding:$inner-40;
	background-color:$bg-color1;
	border-radius:$border-radius;
    @include laptop{
	    padding:$inner-30;
	} 
	@include tablet{
	    padding:$inner-30;
	}
}
.community-img{
   img{
	   max-width:600px;
      @include laptop{
	      max-width:400px;
	  } 
	  @include desktop{
	      max-width:500px;
	  }@include laptop{
	      max-width:400px;
	  }@include tablet{
	      max-width:100%;
		  margin-bottom:30px;
	  }
   }
}


/* ==============================================

18. Contact Area

============================================== */
.contact-info{
	text-align:center;
	padding:0  30px;
	.info-logo{
		position:relative;
		width:90px;
		height:90px;
		border-radius:$round-radius;
		box-shadow:$box-shadow;
		margin:auto;
		margin-bottom:25px;
		
		img{
			
		bottom: 0;
		width: 30px;
		height: 30px;
		object-fit: cover;
		top: 0;
		left: 0;
		right: 0;
		margin: auto;
		position: absolute;
		}
	}
	.info-content{
		h5{
			margin-bottom:15px;
		}
		p{
			a{
			color:$font-color-base;
		}
		}
		
	}
	@include tablet{
		padding-left:0;
		margin-bottom:$inner-30;
		text-align:left;
		.info-logo{
			margin-left:0;
		}
	}
}
.contact-image{
	
	img{
		@include tablet{
			max-width:100%;
			margin-top:$inner-30;
		}
	}
}




/* ==============================================

19. Footer Area

============================================== */

.scrollToTop {
    margin: 0 15px 15px 0;
    text-align: center;
    text-decoration: none;
    position: fixed;
    bottom: 0;
    background: $bg-color1;
    right: 0;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    text-align: center;
    line-height: 40px;
	box-shadow:$box-shadow;
	z-index:111;
}

.scrollToTop:hover {
  text-decoration: none;
}

.footer-wrapper {
	overflow: hidden;
}

.footer-area{
    margin-top:210px;
	&:before{
    content: "";
    height: 700px;
    border-top-left-radius: 270px;
    border-top-right-radius: 160px;
    background: $bg-color2;
    display: block;
    position: absolute;
    transform: skewY(6deg);
	left: -50px;
    right: -70px;
    top:-130px;
	z-index:2;
	@include desktop{
	    border-top-left-radius: 130px;
        border-top-right-radius: 210px;
		transform: rotate(3deg);
		top: -106px;
	}
	@include laptop{
	   display:none;
	}
	@include tablet{
	  display:none;
	}
  }
  @include desktop{
    
		margin-top: 133px;
  }  
  @include tablet{
     margin-top:0;
	 padding-top:$inner-90;
  } 
  @include laptop{
     margin-top:0;
	 padding-top:$inner-90;
  }
}
.footer-widget-wrapper{
    padding-bottom:$inner-60;
	
	@include laptop{
		padding-bottom:$inner-40;
	}
	@include tablet{
		padding-bottom:$inner-40;
	}
}
.footer-logo{
	margin-bottom:15px;
	display:block;
	
	img{
	    position:relative;
		z-index:3;
	}
}
.footer-widget{
    margin-bottom:$inner-30;
	position:relative;
	z-index:2;
    .widget-title{
		margin-bottom:10px;
		h5{
			margin:0;
			color:rgba($font-color4, 0.9);
		}
	}
	p{
		color:rgba($font-color4, 0.9);
	}
	.widget-list{
		margin:0;
		li{
			margin-bottom:5px;
		}
		li a{
			color:rgba($font-color4, 0.9);
			
			&:hover{
			    text-decoration:underline;
			} 
		}
	}
    .social-link{
		li{
			border-top:1px solid transparent;
		}
		li a{
			font-size:20px;
			color:rgba($font-color4, 0.9);
			
			&:hover{
			  color:rgba($font-color4, 1);
		   }
		}
		li:hover{
			border-top:$form-border2;
		}
	}
	form{
		@include placeholder {
		color: $font-color4;
	}
		input{
			 @include form-size(
				  $form-size,$form-space-v,$form-space-h - 15,$form-radius
			  ) ;
			@include form-variant(
				  $form-color,$form-bg2,$form-border2, $form-color,$form-bg2,$form-border2
			  );
		}
		button{
			@include form-variant(
				  $form-color2,$form-bg3,$form-border2, $form-change-color,transparent,$form-change-border
			  );
			   @include form-size(
				  $form-size,$form-space-v,$form-space-h,$form-radius
			  ) ;
		}
	}
}

.footer-copyright-wrapper{
    padding-bottom:30px;
    position:relative;
	z-index:2;
	p,a{
		margin:0;
		color:rgba($font-color4, 0.9);
	}
	a:hover{
	   &:hover{
		  color:rgba($font-color4, 1);
	   }
	}
}

.footer-shape{
    position:absolute;
	width:100%;
	.left{
		position:absolute;
		bottom:-40px;
		left:-40px;
		
		img{
		    z-index:2;
			position:relative;
			@include desktop{
				max-width:300px;
			}@include laptop{
				max-width:300px;
			}
		}
	}
	.right{
		position: absolute;
		right: -90px;
		bottom: -120px;
		img{
		    z-index:2;
			position:relative;
			@include desktop{
				max-width:300px;
			}@include laptop{
				max-width:300px;
			}
		}
	}
	@include tablet{
	   display:none;
	}
}

.list-inline-item {
	color: $font-color2;
	font-weight: 600;
}
.blog-author-tags {
	a {
		color: $placeholder-color;
		text-transform: capitalize;
		margin: 0 5px;
		transition: all .4s linear 0s;
		&:hover {
			color: $bg-color2;
		}
	}
}
.footer-widget {
	z-index: 5;
}



.swiper-slide-duplicate-prev img {
    border: 11px solid $bg-color1;
}
.swiper-slide-duplicate-prev  {
    border: 11px solid $bg-color1;
}




/* ==============================================

	Text Generic

============================================== */

.font-color2 {
	color: $font-color2;
}

.font-color3 {
	color: $font-color3;
}

.font-color5 {
	color: $font-color5;
}


