 
 
/*  0. Normalize  */

body,html {
  -ms-text-size-adjust: 100%; 
  -webkit-text-size-adjust: 100%; 
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
  position:relative;
}

audio,
canvas,
progress,
video {
  display: inline-block; 
  vertical-align: baseline; 
}
audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a{
   transition:$transition-base;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
  color:$link-color;
}
abbr[title] {
  border-bottom: 1px dotted;
}
ol, ul{
   padding-left:20px;
}
b,
strong {
  font-weight: bold;
}
dfn {
  font-style: italic;
}


mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}
i, cite, em, var, address, dfn{
   font-style:normal;
}
img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-size: inherit;
}
.form-control{
   height:auto;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit; 
  font: inherit; 
  margin: 0; 
}
 textarea:focus,
 textarea:active,
 button:focus,
  button:active,
  input:active,
  input:focus,
 .btn:focus,
 .btn.active {
   background-image: none !important;
   outline: 0;
   -webkit-box-shadow: none !important;
   box-shadow: none !important;
 }
.page-link:focus {
    outline: 0;
    box-shadow: none;
}
button,
select {
  text-transform: none;
}

button,
html input[type="button"], 
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; 
  cursor: pointer; 
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; 
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; 
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; 
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
legend {
  border: 0; 
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}